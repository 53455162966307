.d-grid {
  display: grid;
  align-items: center;
  justify-content: center;
}

.width-250px {
  width: 250px !important;
}

.col-padding {
  padding: 0 15px;
}

.col {
  padding-left: 0;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.col h6 {
  padding-bottom: 0.5rem;
}

.col .section-heading {
  padding-left: 0.2rem;
  padding-bottom: 0.6rem;
}

.mc-form-heading .col {
  padding-left: 0;
}