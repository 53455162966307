@import "colours";
@import "typography";
@import "variables";

.filters-table {
  width: 100%;
  border-collapse: collapse;
}

.filter-container-wrapper {
  margin: 5px 0 0 0;
  padding: 3px 0 8px;
  transition: background-color $animation-duration ease-in-out;
}

.filter-container-wrapper.open {
  background-color: #ebeef2;
}

.filter-container-wrapper.closed {
  background-color: #ebeef2;
  padding: 3px 0 0;
}

.filter-container-control-row {
  padding: 0.8rem 0 0.8rem 1rem;
}

.filter-label {
  color: $mc-blue-01;
  transition: color $animation-duration ease-in-out;
}

.filter-heading {
  color: $mc-blue-01;
  font-family: $header-font;
  font-weight: 200 !important;
}

.w-33 {
  width: 33.333333%;
  min-width: 265px !important;
}

.w-half {
  width: 50%;
}

.filter-border-bottom {
  border-bottom: 1px solid $mc-blue-01;
}

.filter-container {
  overflow: hidden;
}

.filter-container.open {
  max-height: 600px;
  transition: all $animation-duration ease-in-out;
  //border-bottom: 1px solid transparent;
}

.filter-container.closed {
  max-height: 0;
  transition: all $animation-duration ease-in-out;
  //border-bottom: 1px solid $mc-grey-05;
}


.filter-inner-container-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
}

.filter-item {
  position: relative;
  margin: 10px 15px 5px;
  width: calc(50% - 30px);

  &:only-child {
    width: calc(100% - 30px);
  }
}

@media only screen and (max-width: 550px) {
  .filter-item {
    width: calc(100% - 30px);
  }
}
