@import "colours";
@import "typography";
@import "variables";

.mobile-view-expanded-row-wrapper-lite,
.mobile-view-wrapper {
  border-radius: 4px;
  margin: 10px;
}

.mobile-view-expanded-row-wrapper {
  border-radius: 4px;
  margin: 0 16px;
}

.supplemental-log-item {
  border-radius: 4px;
  padding: 15px 10px;
  margin: 10px 0;
}

.mobile-view-expanded-row-wrapper-lite {
  background-color: $mc-grey-02;
}

.supplemental-log-item,
.mobile-view-expanded-row-wrapper,
.mobile-view-wrapper {
  box-shadow: $mc-grey-05 0 0 5px 5px;
}

.mobile-view-wrapper {
  background-color: $mc-white-01;
  padding: 15px;
  align-items: stretch;

  transition: background-color ease-in-out $animation-duration / 2;

  &:active {
    background-color: $mc-grey-05;
  }
}

.supplemental-log-item {
  background-color: $mc-grey-02;
}

.mobile-view-expanded-row-wrapper {
  background-color: $mc-grey-02;
}

.mobile-view-expanded-row-wrapper-lite::after,
.mobile-view-expanded-row-wrapper::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $mc-blue-01;
  opacity: 1;
  visibility: visible;
  top: -3%;
  left: 46%;
  margin-top: 15px;
}

.mobile-view-section {
  display: inline-block;
  position: relative;
}

.mobile-view-section-fg-1 {
  flex-grow: 1;
}

.mobile-view-section-fg-8 {
  flex-grow: 8;
}

.mobile-view-actions {
  justify-content: end;
  align-items: end;
}

.mobile-view-initials-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-view-actions-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mobile-view-initials {
  background-color: $mc-blue-02;
  padding: 15px;
  font-family: $header-font;
  color: $mc-white-01;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: auto;
}

.mobile-view-actions-icons {
  color: $mc-blue-02;
  margin: 5px 0;
}

.mobile-view-details-wrapper {
  padding: 0 0 0 15px;
}

.mobile-view-details {
  margin-bottom: 5px;

  &:first-child {
    margin-top: 3px;
  }

  &:last-child {
    margin-bottom: 0
  }
}

.mobile-view-extended-details {
  max-height: 700px;
  transition: all $animation-duration / 2 ease-in-out;
}
