@import "colours";
@import "typography";
@import "variables";

.mc-tab-list {
  margin-bottom: 1rem;
  padding: 0;
}

.mc-tab,
.mc-tab--selected {
  transition: background-color ease $animation-duration;
}

.mc-tab {
  cursor: pointer;
  display: inline-block;
  list-style: none;
  margin-left: 1rem;
  padding: 0.5rem 0.5rem;
  position: relative;
  font-family: $header-font;

  &:hover {
    //background-color: $mc-dark-blue-hover;
    border-bottom: 2px solid #405368;
  }
}

.mc-tab--selected {
  background-color: #fff;
  border-bottom: 2px solid #405368;
  color: $mc-blue-01;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    //color: #fff;
  }
}
