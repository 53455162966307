.oplog-log-option {
  padding-right: 0 !important;

  &:nth-child(odd) {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .oplog-log-option {
    &:nth-child(even) {
      padding-left: 0 !important;
    }
  }
}
