@import "colours";
@import "typography";
@import "variables";

.pulse-table-wrapper{
  margin-left: 1rem;
  margin-right: 1rem;
}

.pulse-table-header {
  background-color: #fff;
  border-bottom: 1px solid #eee;
}

.pulse-table-header-item {
  font-family: $header-font;
  font-size: 0.95rem;
  color: #858C91;
  font-weight: 400;
  min-width: 170px !important;
  width: 12.5%;
}

.pulse-table-row-item {
  font-family: $body-font;
  font-size: 1em;
}

.expandable-section {
  overflow: hidden;
  transition: max-height $animation-duration / 2 ease-in-out;
  position: relative;
}

.expandable-section.open {
  max-height: 700px;
  overflow: hidden auto;
}

.expandable-section.closed {
  max-height: 0;
  overflow: hidden;
}

.expandable-table-bottom {
  border-bottom: 10px solid $mc-blue-01;
}

.expandable-odd-row:hover,
.expandable-even-row:hover {
  cursor: pointer;
}

.expandable-even-row {
  //background-color: $mc-white-01;
  border-bottom: 1px solid #eee;
}
.expandable-odd-row {
  //background-color: $off-white
  border-bottom: 1px solid #eee;
}

.expanded {
  box-shadow: 0 7px 2px $mc-grey-04;
  transition: all $animation-duration / 2 ease-in-out;
}

.expanded-under {
  transition: all $animation-duration / 2 ease-in-out;
  //background: linear-gradient(rgba(0, 0, 0, 0), $mc-grey-04);
  background: #eee;
  height: 1px;
}
