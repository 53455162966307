@import "colours";
@import "typography";
@import "variables";

.supplemental-table-header {
  margin: 15px;
  color: #000;
  font-family: $body-font;
  font-weight: 500;
  padding-top: 0.6rem;
}

.supplemental-table-header-item {
  font-family: $header-font;
  font-weight: 600;
  padding: 0.5rem 1rem;
}

.supplemental-table-row-item {
  font-family: $body-font;
  padding: 0.5rem 1rem;
  font-size: 1em;
}

.supplemental_row {
  background-color: #fff !important;
}

.supplemental_column {
  border: none !important;
}

.pulse-table tr.supplemental-table-row:last-child td {
  border-bottom: 50px solid red;
}
