@import "colours";

.mc-form-heading {
  background-color: #ebeef2;
  color:#7b8185;
  padding: 1rem;
  //margin: 0 !important;
  margin-top: 2rem;
}

.mc-form-row-optional,
.mc-form-row {
  padding: 15px 25px 15px 30px;
  background-color: #ebeef2;
}

@media only screen and (max-width: 850px) {
  .mc-form-row {
    margin: 15px 30px !important;
  }

  .mc-form-row-mobile {
    margin: 15px !important;
  }
}

.row-modifier {
  .mc-form-row-optional,
  .mc-form-row {
  background-color: #fff;
}
}

.row-modifier .mc-form-heading {
  background-color: #fff;
}