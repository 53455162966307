@import "colours";
@import "variables";
@import "typography";

.input-fields,
.input-fields-filters{
  background-color: $mc-white-01;
  width: 100%;
  border: none;
  font-family: $body-font;
  min-width: 195px !important;
  padding: 9px 15px;
  border: 1px solid #ebeef2;
}
.input-fields {
  padding: 9px 10px;
}

.input-fields-filters {
  padding: 9px 17px;
}

.text-area-inputs {
  min-height: 100px;
}
